import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { removeSelectedTracksAction } from './playlist.actions';
import { tap } from 'rxjs';

@Injectable()
export class PlaylistEffects {
  // inject
  store = inject(Store);
  actions$ = inject(Actions);

  constructor() {}

  removeSelectedTracks$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(removeSelectedTracksAction),
        tap((action) => {
          alert('remove tracks!' + action.tracks.length);
        })
      ),
    { dispatch: false }
  );
}
