import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TreeNode } from 'primeng/api';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Track } from '../../../music-archive/track';
import { DataService } from '../../../services/data.service';
import { Group } from '../../../services/group';
import {
  AddDirectoryAction,
  AddDirectoryFailedAction,
  AddDirectorySuccessfulAction,
  AddGroupAction,
  AddGroupSuccessfulAction,
  ArchiveActions,
  LoadTracksFailedAction,
  RemoveGroupAction,
  RemoveGroupSuccessfulAction,
  RenameGroupAction,
  RenameGroupSuccessfulAction,
  cmAddDirectoryToPlaylistAction,
  cmAddItunesPlaylistsToPlaylistAction,
  cmAddItunesTrackToPlaylistAction,
  cmAddPlaylistsToPlaylistAction,
  cmAddTrackToPlaylistAction,
  cmAnalyseTrackAction,
  cmBpmSetAction,
  cmChangeId3TagsAction,
  cmExportTrackAction,
  cmNormalisizeVolumeAction,
  cmOpenFileManagerAction,
  cmReadId3TagsAction,
  cmRemoveAllTracksFromPlaylistAction,
  cmRemoveFromHarddriveAction,
  cmRemoveMarkedTracksFromPlaylistAction,
  cmReshufflyPlaylistAction,
  cmSavePlaylistAction,
  cmSearchCoverAction,
  cmSelectAllTracksInPlaylistAction,
  loadGroupsFailedAction,
  loadGroupsSucceededAction,
  loadTracksSucceededAction,
} from '../actions/archive.actions';
import { loadGroupsAction, loadTracksAction } from './../actions/archive.actions';

@Injectable()
export class ArchiveEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private dataService: DataService,
    public toastController: ToastController,
    public alertController: AlertController
  ) {}

  loadGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadGroupsAction),
      switchMap((action) =>
        this.dataService.getGroups$().pipe(
          map((groups: TreeNode[]) => loadGroupsSucceededAction({ groups })),
          catchError((error) => of(loadGroupsFailedAction({ error })))
        )
      )
    )
  );

  loadTracks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTracksAction),
      switchMap((action) =>
        this.dataService.getTracks$().pipe(
          map((tracks: Track[]) => loadTracksSucceededAction({ tracks })),
          catchError((error) => {
            console.log('error: ', error);
            this.presentAlert('Could not load tracks (no internet connection?');
            return of(new LoadTracksFailedAction({ groupID: action.groupID, error }));
          })
        )
      )
    )
  );

  addDirectory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArchiveActions.ADD_DIRECTORY),
      switchMap((action: AddDirectoryAction) => this.dataService.addDirectory(action.payload.dir)),
      map((ret: boolean) => new AddDirectorySuccessfulAction()),
      catchError((error) => {
        console.log('error');
        console.log(error);
        this.presentAlert('Could not add directory (no internet connection?');
        return of(new AddDirectoryFailedAction());
      })
    )
  );

  removeGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArchiveActions.REMOVE_GROUP),
      switchMap((action: RemoveGroupAction) =>
        this.dataService.removeGroup(action.payload.group).pipe(
          map((ret: boolean) => new RemoveGroupSuccessfulAction({ group: action.payload.group })),
          catchError((error) => {
            console.log('error while adding group: ', error);
            return of(new RemoveGroupSuccessfulAction({ group: action.payload.group })); // todo
          })
        )
      )
    )
  );

  renameGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArchiveActions.RENAME_GROUP),
      switchMap((action: RenameGroupAction) =>
        this.dataService.renameGroup(action.payload.group, action.payload.newName).pipe(
          map((ret: boolean) => {
            return new RenameGroupSuccessfulAction({ group: action.payload.group });
          }),
          catchError((error) => {
            console.log('error while adding group: ', error);
            return of(new RenameGroupSuccessfulAction({ group: action.payload.group })); // todo
          })
        )
      )
    )
  );

  addGroup = createEffect(() =>
    this.actions$.pipe(
      ofType(ArchiveActions.ADD_GROUP),
      switchMap((action: AddGroupAction) =>
        this.dataService.addGroup(action.payload.groupName).pipe(
          map((addedGroup: Group) => {
            return new AddGroupSuccessfulAction({
              group: Group.treeNodeFromGroup(addedGroup),
            });
          }),
          catchError((error) => {
            console.log('error while adding group: ', error);
            return of(new AddGroupSuccessfulAction({ group: null })); // todo
          })
        )
      )
    )
  );

  cmAddTrack$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cmAddTrackToPlaylistAction),
        tap((action) => {
          console.log('### [ARCHIVE EFFECTS] cmAddTrackAction');
        })
      ),
    { dispatch: false }
  );

  cmAddDirectory$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cmAddDirectoryToPlaylistAction),
        tap((action) => {
          console.log('### [ARCHIVE EFFECTS] cmAddDirectoryAction');
        })
      ),
    { dispatch: false }
  );

  cmAddItunesTrack$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cmAddItunesTrackToPlaylistAction),
        tap((action) => {
          console.log('### [ARCHIVE EFFECTS] cmAddItunesTrackAction');
        })
      ),
    { dispatch: false }
  );

  cmAddPlaylists$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cmAddPlaylistsToPlaylistAction),
        tap((action) => {
          console.log('### [ARCHIVE EFFECTS] cmAddPlaylistsAction');
        })
      ),
    { dispatch: false }
  );

  cmAddItunesPlaylists$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cmAddItunesPlaylistsToPlaylistAction),
        tap((action) => {
          console.log('### [ARCHIVE EFFECTS] cmAddItunesPlaylistsAction');
        })
      ),
    { dispatch: false }
  );

  cmRemoveMarkedTracks$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cmRemoveMarkedTracksFromPlaylistAction),
        tap((action) => {
          console.log('### [ARCHIVE EFFECTS] cmRemoveMarkedTracksAction', action.list);
        })
      ),
    { dispatch: false }
  );

  cmRemoveAllTracks$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cmRemoveAllTracksFromPlaylistAction),
        tap((action) => {
          console.log('### [ARCHIVE EFFECTS] cmRemoveAllTracksAction');
        })
      ),
    { dispatch: false }
  );

  cmSelectAllTracks$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cmSelectAllTracksInPlaylistAction),
        tap((action) => {
          console.log('### [ARCHIVE EFFECTS] cmSelectAllTracksAction');
        })
      ),
    { dispatch: false }
  );

  cmRemoveFromHarddrive$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cmRemoveFromHarddriveAction),
        tap((action) => {
          console.log('### [ARCHIVE EFFECTS] cmRemoveFromHarddriveAction', action.list);
        })
      ),
    { dispatch: false }
  );

  cmSavePlaylist$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cmSavePlaylistAction),
        tap((action) => {
          console.log('### [ARCHIVE EFFECTS] cmSavePlaylistAction');
        })
      ),
    { dispatch: false }
  );

  cmReshufflyPlaylist$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cmReshufflyPlaylistAction),
        tap((action) => {
          console.log('### [ARCHIVE EFFECTS] cmReshufflyPlaylistAction');
        })
      ),
    { dispatch: false }
  );

  cmBpmSet$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cmBpmSetAction),
        tap((action) => {
          console.log('### [ARCHIVE EFFECTS] cmBpmSetAction', action.position);
        })
      ),
    { dispatch: false }
  );

  cmReadId3Tags$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cmReadId3TagsAction),
        tap((action) => {
          console.log('### [ARCHIVE EFFECTS] cmReadId3TagsAction', action.position);
        })
      ),
    { dispatch: false }
  );

  cmChangeId3Tags$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cmChangeId3TagsAction),
        tap((action) => {
          console.log('### [ARCHIVE EFFECTS] cmChangeId3TagsAction', action.position);
        })
      ),
    { dispatch: false }
  );

  cmSearchCover$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cmSearchCoverAction),
        tap((action) => {
          console.log('### [ARCHIVE EFFECTS] cmSearchCoverAction', action.position);
        })
      ),
    { dispatch: false }
  );

  cmNormalisizeVolume$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cmNormalisizeVolumeAction),
        tap((action) => {
          console.log('### [ARCHIVE EFFECTS] cmNormalisizeVolumeAction', action.position);
        })
      ),
    { dispatch: false }
  );

  cmAnalyseTrack$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cmAnalyseTrackAction),
        tap((action) => {
          console.log('### [ARCHIVE EFFECTS] cmAnalyseTrackAction', action.position);
        })
      ),
    { dispatch: false }
  );

  cmOpenFileManager$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cmOpenFileManagerAction),
        tap((action) => {
          console.log('### [ARCHIVE EFFECTS] cmOpenFileManagerAction');
        })
      ),
    { dispatch: false }
  );

  cmExportTrack$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cmExportTrackAction),
        tap((action) => {
          console.log('### [ARCHIVE EFFECTS] cmExportTrackAction', action.position);
        })
      ),
    { dispatch: false }
  );

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 2000,
      // showCloseButton: true,
      color: 'warning',
      position: 'middle',
      // closeButtonText: 'Done'
    });
    toast.present();
  }

  async presentAlert(message: string) {
    const alert = await this.alertController.create({
      header: 'Error',
      // subHeader: 'Subtitle',
      mode: 'ios',
      message,
      buttons: ['OK'],
    });

    await alert.present();
  }
}
