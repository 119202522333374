import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, of, timer } from 'rxjs';
import { catchError, filter, first, map, switchMap, take, tap } from 'rxjs/operators';
import { UltraMixerLicense } from '../../../login/interfaces/um-keys-user';
import { AuthService } from '../../../login/services/auth.service';
import { UMKeyService } from '../../../login/services/um-key.service';
import { UM6ControlService } from '../../../services/um6-control.service';
import {
  activateDemoAction,
  activateDemoFailedAction,
  activateDemoSucceededAction,
  activateLicenseAction,
  activateLicenseFailedAction,
  activateLicenseSucceededAction,
  createAccountAction,
  createAccountFailedAction,
  createAccountSucceededAction,
  initAccountVerificationAction,
  initAccountVerificationFailedAction,
  initAccountVerificationSucceededAction,
  initLoginScreenAction,
  initLoginScreenSucceededAction,
  signInAction,
  signInFailedAction,
  signInSuccessfulAction,
  startUltraMixerAction,
  triggerLoginContextMenuAction,
  uploadUltraMixerLicenceAction,
  uploadUltraMixerLicenceFailedAction,
  uploadUltraMixerLicenceSucceededAction,
} from '../actions/login.actions';
import { getPassword, getUsername } from '../reducers/login.reducer';
import { LoginService } from './../../../login/services/login.service';

@Injectable()
export class LoginEffects {
  // services
  private loginService = inject(LoginService);
  private store = inject(Store);
  private actions$ = inject(Actions);
  private authService = inject(AuthService);
  private umKeyService = inject(UMKeyService);
  private router = inject(Router);
  private um6ControlService = inject(UM6ControlService);

  constructor() {}

  signIn$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(signInAction),
        switchMap((action) =>
          this.loginService
            .signIn$(action.email, action.password)
            .pipe(
              map((licenses: UltraMixerLicense[]) =>
                signInSuccessfulAction({ email: action.email, password: action.password, licenses: licenses })
              )
            )
        ),
        catchError((error, caught) => {
          console.log(error.code);
          if (error.code == undefined) {
            error.code = 'auth/not-verified';
          }
          console.error('>>> sign error', error);
          this.store.dispatch(signInFailedAction({ signInError: this.authService.mapAuthErrorCodeToMessage(error.code) }));
          return caught;
        })
      ),
    { dispatch: true }
  );

  signUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createAccountAction),
      switchMap((action) => {
        if (action.password !== action.repeatPassword) {
          return of(createAccountFailedAction({ signUpError: 'Passwords do not match' }));
        }
        return this.authService.signUp$(action.email, action.password).pipe(
          switchMap(() => {
            this.router.navigate(['/login/verification']);
            return of(createAccountSucceededAction({ username: action.email, password: action.password }));
          })
        );
      }),
      catchError((error, caught) => {
        console.log(error.code);
        if (error.code == undefined) {
          error.code = 'auth/not-verified';
        }
        this.store.dispatch(createAccountFailedAction({ signUpError: this.authService.mapAuthErrorCodeToMessage(error.code) }));
        return caught;
      })
    )
  );

  activateDemoLicense$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(activateDemoAction),
        tap(() => console.log('yooo')),
        switchMap((action) =>
          this.loginService.activateDemo$(action.license).pipe(
            map(() => activateDemoSucceededAction({ license: action.license })),
            catchError((error) => {
              console.error(error);
              return of(activateDemoFailedAction({ error: error }));
            })
          )
        )
      ),
    { dispatch: true }
  );

  activateLicense$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(activateLicenseAction),
        switchMap((action) =>
          this.loginService.activateLicense$(action.license).pipe(
            map(() => activateLicenseSucceededAction({ license: action.license })),
            catchError((error) => {
              console.error(error);
              return of(activateLicenseFailedAction({ error: error.code }));
            })
          )
        )
      ),
    { dispatch: true }
  );

  activateLicenseSucceeded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(activateLicenseSucceededAction),
        tap((action) => alert('UM activated! Close window now...'))
      ),
    { dispatch: false }
  );

  accountVerification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initAccountVerificationAction),
      switchMap(() => {
        return timer(5000, 3000).pipe(
          take(10),
          switchMap(() => {
            return this.authService.checkEmailVerification$();
          }),
          filter((isVerified) => isVerified),
          switchMap(() => {
            return of(initAccountVerificationSucceededAction());
          }),
          first()
        );
      }),
      catchError((error, caught) => {
        this.store.dispatch(initAccountVerificationFailedAction());
        return caught;
      })
    )
  );

  accountVerified$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initAccountVerificationSucceededAction),
      switchMap(() => {
        return combineLatest([this.store.select(getUsername), this.store.select(getPassword)]);
      }),
      switchMap((values) => {
        return of(signInAction({ email: values[0], password: values[1] }));
      }),
      catchError((error, caught) => {
        this.store.dispatch(initAccountVerificationFailedAction());
        return caught;
      })
    )
  );

  triggerLoginContextMenu$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(triggerLoginContextMenuAction),
        tap((action) => this.um6ControlService.clickContextMenu(action.selectedText))
      ),
    { dispatch: false }
  );

  uplodateUltraMixerLicence$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(uploadUltraMixerLicenceAction),
        switchMap((action) => this.loginService.uploadUltraMixerLicense$(action.license)),
        map((licenses: UltraMixerLicense[]) => uploadUltraMixerLicenceSucceededAction({ licenses: licenses })),
        catchError((error, caught) => {
          // of(null);
          return of(uploadUltraMixerLicenceFailedAction({ error }));
        })
      ),
    { dispatch: true }
  );

  startUltraMixer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(startUltraMixerAction),
        switchMap((action) => this.um6ControlService.startUltraMixer$())
      ),
    { dispatch: false }
  );

  initLoginScreen$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initLoginScreenAction),
        switchMap((action) => this.um6ControlService.getOS$().pipe(map((os: string) => initLoginScreenSucceededAction({ os }))))
      ),
    { dispatch: true }
  );
}
