import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileInfo, Filesystem, ReaddirResult } from '@capacitor/filesystem';
import { Store } from '@ngrx/store';
import PouchDB from 'pouchdb';
import { TreeNode } from 'primeng/api';
import { Observable, concat, from, of } from 'rxjs';
import { catchError, delay, map, switchMap } from 'rxjs/operators';
import { GroupChangedAction } from '../core/redux/actions/archive.actions';
import { Track } from '../music-archive/track';
import { Group } from './group';

import AllDocsResponse = PouchDB.Core.AllDocsResponse;

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private db: any;

  // configure Bugsnag asap
  // private bugsnagClient = bugsnag('6c3e1ee915a118444b4aae35e2f02da5');

  constructor(private http: HttpClient, private store: Store) {
    // PouchDB.debug.enable('*');

    this.db = new PouchDB('https://bfc34c7c-0773-47ce-bbe8-0293907eaf5f-bluemix.cloudant.com/ultramixer', {
      auth: {
        username: 'ghtheentriaterysilybrion',
        password: '2fd6765ee391e4d34d7c24a54e89acb0f31ef710',
      },
    });

    let changes = this.db
      .changes({
        since: 'now',
        live: true,
        include_docs: true,
      })
      .on('change', (change) => {
        console.log('change: ', change);
        if (change.doc) {
          if (change.doc._id.startsWith('group:')) {
            this.store.dispatch(new GroupChangedAction({ group: change.doc as Group }));
          }
        }
      })
      .on('complete', (info) => {
        // changes() was canceled
        console.log('complete:', info);
      })
      .on('error', (err) => {
        console.log('DB Error:', err);
        // this.bugsnagClient.notify(err);
      });
  }

  getGroups$(): Observable<TreeNode[]> {
    return this.getGroupsMocked$();
  }

  getGroupsMocked$(): Observable<TreeNode[]> {
    return new Observable<TreeNode[]>((observer) => {
      const nodes: TreeNode[] = [];
      for (let i = 1; i <= 40; i++) {
        const doc = { _id: `group${i}`, name: `Group ${i}` } as Group;
        const node = Group.treeNodeFromGroup(doc);
        nodes.push(node);
      }
      observer.next(nodes);
      observer.complete();
    }).pipe(delay(1000));
  }

  getGroupsDB$(): Observable<TreeNode[]> {
    return from<AllDocsResponse<Group>>(
      this.db.allDocs({
        include_docs: true,
        startkey: 'group:',
        endkey: 'group:\uffff',
      })
    ).pipe(
      map((response: AllDocsResponse<Group>) =>
        response.rows.map((row: any) => {
          return Group.treeNodeFromGroup(row.doc);
        })
      )
    );
  }

  getTracks$(): Observable<Track[]> {
    //return this.getTracksMocked$();
    return this.getTracksDB$();
  }

  // TODO: remove??
  getTracksMocked$(): Observable<Track[]> {
    var random = require('lodash/random');

    return new Observable<Track[]>((observer) => {
      const tracks: Track[] = [];
      for (let i = 1; i <= 20; i++) {
        tracks.push({
          _id: `id-${i}`,
          artist: `Artist ${i}`,
          title: `Title ${i}`,
          bpm: random(78, 149),
          length: random(2 * 60, 5 * 60),
          rating: random(1, 5),
        } as Track);
      }
      observer.next(tracks);
      observer.complete();
    }).pipe(delay(1000));
  }

  getTracksDB$(): Observable<Track[]> {
    return from(
      this.db.allDocs({
        include_docs: true,
        startkey: 'track:',
      })
    ).pipe(map((response: any) => response.rows.map((row) => row.doc as Track)));
  }

  addDirectory(dir: File): Observable<any> {
    console.log('addDirectory: dir: ', dir);

    // directory: FilesystemDirectory.Documents

    /*
        return new Observable<boolean>(observer => {
            Filesystem.readdir({
                path: dir.path
            }).then((value: ReaddirResult) => {
                console.log("value");
                console.log(value);

                observer.next(true);
                observer.complete();

            }).catch((reason:any) => {
                console.log("reason");
                console.log(reason);
            })
        });*/

    return this.addGroup(dir.name).pipe(
      switchMap((result: Group) => {
        console.log('result');
        console.log(result);
        return from(
          Filesystem.readdir({
            path: dir.name,
          })
        ).pipe(
          map((ret: ReaddirResult) => {
            return this.addFiles(ret.files);
          })
        );
      }),
      catchError((err) => {
        console.log('err');
        console.log(err);
        return of('error');
      })
    );
  }

  // todo: write unit test!
  addGroup(name: string): Observable<Group> {
    const newGroup = {
      _id: `group:${name}:${new Date().getTime()}`,
      name,
    } as Group;

    return from(this.db.put(newGroup)).pipe(
      map((e) => {
        console.log('e');
        console.log(e);
        return newGroup;
      }),
      catchError((err) => {
        console.log('err');
        console.log(err);
        return of(err);
      })
    );
  }

  // todo: write unit test!
  private addFiles(files: FileInfo[]): Observable<any> {
    const addJobs: Observable<any>[] = [];

    files.forEach((file: FileInfo, index: number) => {
      addJobs.push(
        from(
          this.db.put({
            _id: `track:${file.name}}`,
            artist: `Artist ${index}`,
            title: `Title ${index}`,
            src: '', // todo
            length: 215000, // todo:
            trackNumber: 0, // todo
          } as Track)
        ).pipe(
          map((e) => {
            console.log('e');
            console.log(e);
            return true;
          }),
          catchError((err) => {
            console.log('err');
            console.log(err);
            return of(err);
          })
        )
      );
    });

    return concat(addJobs);
  }

  removeGroup(group: TreeNode): Observable<boolean> {
    console.log('data service: remove group ' + group);
    return from(this.db.remove(group.data._id, group.data._rev)).pipe(map((ret) => ret as boolean));
    // return of(true).pipe(delay(1000));
  }

  renameGroup(group: TreeNode, newName: string): Observable<boolean> {
    const newGroup = { ...group.data, name: newName };
    return from(this.db.put(newGroup)).pipe(map((ret: any) => ret.ok));
  }

  // todo
  login(username: string, password: string): Observable<boolean> {
    console.log('login');
    return of(true).pipe(delay(1000));
  }

  updateTrack(track: Track) {}
}
