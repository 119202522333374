
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap, tap } from 'rxjs/operators';
import { AudioService } from '../../../services/audio.service';
import { loadSampleBankAction, loadSampleBankSucceededAction, playSampleAction } from '../actions/audiosampler.actions';
import { BeatMakerState } from '../reducers/beatmaker.reducer';

@Injectable()
export class AudioSamplerEffects {
  constructor(private actions$: Actions, private store: Store<BeatMakerState>, private audioService: AudioService) {}

  // loadSampleBankAction$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(loadSampleBankAction),
  //     switchMap((action) => this.audioService.initializingAudioSampleBank$().pipe()),
  //     map(() => loadSampleBankSucceededAction())
  //   )
  // );

  playSampleAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(playSampleAction),
        tap((action) => this.audioService.playSampleSlot(action.slotIndex))
      ),
    { dispatch: false }
  );
}
