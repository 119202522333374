import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { ActionTriggerEditorActions, NotifyActionTriggersChangedAction } from '../actions/action-trigger-editor.actions';
import { getActionTriggers } from '../reducers/action-trigger-editor-reducer';

@Injectable()
export class ActionTriggerEditorEffects {
  private store = inject(Store);
  private actions$ = inject(Actions);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  observeForChanges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ActionTriggerEditorActions.ADD_ACTION,
        ActionTriggerEditorActions.DELETE_ACTION,
        ActionTriggerEditorActions.ADD_ACTION,
        ActionTriggerEditorActions.ADD_ACTION_TRIGGER,
        ActionTriggerEditorActions.DELETE_ACTION_TRIGGER,
        ActionTriggerEditorActions.SET_ACTION_TRIGGER_ACTIVE_ACTION
      ),
      withLatestFrom(this.store.select(getActionTriggers)),
      map(([action, actionTriggers]) => {
        // @ts-ignore
        const json = JSON.stringify(actionTriggers);
        return new NotifyActionTriggersChangedAction({ actionTriggersJSON: json });
      })
    )
  );

  notify$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ActionTriggerEditorActions.NOTIFY_ACTION_TRIGGERS_CHANGED_ACTION),
        tap((action: NotifyActionTriggersChangedAction) => {
          console.log('notify UM: ');
          // @ts-ignore
          // tslint:disable-next-line: max-line-length
          // tslint:disable-next-line: no-unused-expression
          // tslint:disable-next-line: max-line-length
          window.ultramixer_actiontriggers &&
            (window as any).ultramixer_actiontriggers.notifyActionTriggersChanged(action.payload.actionTriggersJSON);
        })
      ),
    { dispatch: false }
  );
}
