import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AudioService } from '../../../services/audio.service';
import {
  crossfaderAction,
  killEqHighAction,
  killEqLowAction,
  killEqMidAction,
  setEqHighAction,
  setEqLowAction,
  setEqMidAction,
  setFlangerAction,
  setCutoffAction,
  setResonanceAction,
  killCutoffAction,
  killFlangerAction,
  killResonanceAction,
  setVolumeAction
} from '../actions/mixer.actions';
@Injectable()
export class MixerEffects {
  private actions$ = inject(Actions);
  private audioService = inject(AudioService);

  public static timeRate = 55;

  private timerSubs: Subscription[] = [];

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  crossfaderAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(crossfaderAction),
        tap((action) => {
          this.audioService.setCrossfader(action.value);
        })
      ),
    { dispatch: false }
  );

  volume$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setVolumeAction),
        tap((action) => {
          this.audioService.setVolume(action.playerID, action.volume);
        })
      ),
    { dispatch: false }
  );

  setEqHigh$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setEqHighAction),
        tap((action) => {
          this.audioService.setEQHigh(action.playerID, action.value, true);
        })
      ),
    { dispatch: false }
  );

  setEqMid$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setEqMidAction),
        tap((action) => {
          this.audioService.setEQMid(action.playerID, action.value, true);
        })
      ),
    { dispatch: false }
  );

  setEqLow$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setEqLowAction),
        tap((action) => {
          this.audioService.setEQLow(action.playerID, action.value, true);
        })
      ),
    { dispatch: false }
  );

  killEqHigh$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(killEqHighAction),
        tap((action) => {
          this.audioService.killEqHigh(action.playerID);
        })
      ),
    { dispatch: false }
  );

  killEqMid$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(killEqMidAction),
        tap((action) => {
          this.audioService.killEqMid(action.playerID);
        })
      ),
    { dispatch: false }
  );

  killEqLow$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(killEqLowAction),
        tap((action) => {
          this.audioService.killEqLow(action.playerID);
        })
      ),
    { dispatch: false }
  );

  setFlanger$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setFlangerAction),
        tap((action) => {
          this.audioService.setFlanger(action.playerID, action.value, true);
        })
      ),
    { dispatch: false }
  );

  setResonance$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setResonanceAction),
        tap((action) => {
          this.audioService.setResonance(action.playerID, action.value, true);
        })
      ),
    { dispatch: false }
  );

  setCutoff$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setCutoffAction),
        tap((action) => {
          this.audioService.setCutoff(action.playerID, action.value, true);
        })
      ),
    { dispatch: false }
  );

  killFlanger$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(killFlangerAction),
        tap((action) => {
          this.audioService.killFlanger(action.playerID);
        })
      ),
    { dispatch: false }
  );

  killResonance$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(killResonanceAction),
        tap((action) => {
          this.audioService.killResonance(action.playerID);
        })
      ),
    { dispatch: false }
  );

  killCutoff$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(killCutoffAction),
        tap((action) => {
          this.audioService.killCutoff(action.playerID);
        })
      ),
    { dispatch: false }
  );
}
