import { Injectable, booleanAttribute } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Actions, act, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subscription, combineLatest, interval, of } from 'rxjs';
import { catchError, concatMap, filter, first, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { WaveformCache } from '../../../main/waveform/WaveformCache';
import { Track } from '../../../music-archive/track';
import { AudioService } from '../../../services/audio.service';
import { MidiService } from '../../../services/midi.service';
import { VisualsService } from '../../../services/visuals.service';
import { setLevelsAction, setVolumeAction } from '../actions/mixer.actions';
import { Buffer } from 'buffer';

import {
  activatePlayerAction,
  cueAction,
  deactivatePlayerAction,
  loadIntoPlayerAction,
  loadIntoPlayerFailedAction,
  loadIntoPlayerSuccessfullAction,
  loopAction,
  loopInAction,
  loopOutAction,
  playAction,
  saveCuePointAction,
  setPitchAction,
  setTimeAction,
  setVirtualVelocityAction,
  setVirtualVelocityAvgTimeAction,
  startEndOfSongDisplayAnimationAction,
  stopEndOfSongDisplayAnimationAction,
  scratchAction,
  scratchEndAction,
  scratchStartAction,
  setWasPlayingAction,
  setZoomAction,
  loopBeatAction,
} from '../actions/player.actions';
import {
  isLoopActive,
  getPitch,
  getRemainingTime,
  getSongLength,
  getTime,
  getTrack,
  getWaveformCache,
  isPlaying,
} from '../reducers/player.reducer';
import { playAvgTimeAction } from './../actions/player.actions';
import { WaveformCacheI } from 'src/app/main/waveform/WaveformCacheI';
import { cloneDeep } from 'lodash';


declare var AudioContext;
declare var webkitAudioContext;

@Injectable()
export class PlayerEffects {
  public static timeRate = 10;

  public static waveCount = 0;

  private timerSubs: Subscription[] = [];

  constructor(
    private store: Store,
    private actions$: Actions,
    private visualsService: VisualsService,
    private audioService: AudioService,
    private midiService: MidiService,
    public alertController: AlertController
  ) {
    // alert('UM7 effects');
  }

  loadIntoPlayer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadIntoPlayerAction),
      switchMap((action) => {
        return this.audioService.loadTrack$(action.playerID, action.track).pipe(
          map((waveformCache: WaveformCache) => {
            PlayerEffects.waveCount = 5;
            // TODO: überdenken...player nutzen?
            // const length = this.audioService.getLength(action.playerID);
            // const track = { ...action.track, length };

            const track = { ...action.track };
            return loadIntoPlayerSuccessfullAction({
              playerID: action.playerID,
              track,
              waveformData: waveformCache,
            });
          }),
          catchError((error: any) => {
            console.error('error while loading track', error);
            return of(loadIntoPlayerFailedAction({ ...action, error }));
          })
        );
      })
    )
  );

  loadIntoPlayerFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadIntoPlayerFailedAction),
        map((action) => {
          this.presentAlert(`Could not load track ${action.track.src}- (no internet connection?)`);
        })
      ),
    { dispatch: false }
  );

  play$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(playAction),
        map((action) => {
          this.visualsService.playVideo(); // TODO: only for testing

          this.audioService.play(action.playerID);
        })
      ),
    { dispatch: false }
  );

  playAvgTime$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(playAvgTimeAction),
        tap((action) => {
          // this.visualsService.playVideo();
          // this.audioService.playAvgTime(action.playerID, action.received);

          // LED //
          if (this.audioService.isPlaying(action.playerID)) {
            this.midiService.play(1);
          } else {
            this.midiService.play(0);
          }

          /*
                    let timerSub: Subscription = this.timerSubs[action.payload.playerID];
                    if (timerSub) {
                        timerSub.unsubscribe();
                    }
                    this.timerSubs[action.payload.playerID] = interval(PlayerEffects.timeRate).subscribe(value => {
                        const v = value * PlayerEffects.timeRate;
                        //if (v > fromPlayer.FAKE_LENGTH) {
                        //   this.store.dispatch(new CueAction({playerID: action.payload.playerID}));
                        //}
                        //else {
                        const l = Math.random();
                        this.store.dispatch(MixerActions.setLevelsAction(action.payload.playerID, l, l));
                        this.store.dispatch(new SetTimeAction({playerID: action.payload.playerID, time: v}));
                        //  }
                    });
                    */
        })
      ),
    { dispatch: false }
  );

  stop$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cueAction),
        map((action) => {
          // this.visualsService.stopVideo();
          this.audioService.stop(action.playerID);

          //TODO: check to move to audio service
          if (this.timerSubs[action.playerID]) {
            this.timerSubs[action.playerID].unsubscribe();
          }
          this.timerSubs[action.playerID] = null;
        })
      ),
    { dispatch: false }
  );

  setVirtualVelocity$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setVirtualVelocityAction),
        map((action) => {
          this.audioService.setVirtualVelocity(action.playerID, action.positionMs, action.timems);
        })
      ),
    { dispatch: false }
  );

  setVirtualVelocityAvgTime$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setVirtualVelocityAvgTimeAction),
        map((action) => {
          this.audioService.setVirtualVelocityAvgTime(action.playerID, action.positionMs, action.timems, action.received);
        })
      ),
    { dispatch: false }
  );

  setPitch$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setPitchAction),
        map((action) => {
          this.audioService.setPitchOrTempo(action.playerID, action.value);
        })
      ),
    { dispatch: false }
  );

  setVolume$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setVolumeAction),
        map((action) => {
          this.audioService.setVolume(action.playerID, action.volume);
        })
      ),
    { dispatch: false }
  );

  saveCuePoint = createEffect(
    () =>
      this.actions$.pipe(
        ofType(saveCuePointAction),
        map((action) => action),
        // tslint:disable-next-line: deprecation
        switchMap((action) => combineLatest(of(action), this.store.select(getTrack(action.playerID)))),
        map(([action, track]: [any, Track]) => {
          console.log('track', track);
          console.log('action', action);
          const pos = this.audioService.getPosition(action.playerID);
          console.log('pos', pos);

          // this.dataSerive.saveCuePointFromCurrentTime(action.payload.cuepointIndex);
        })
      ),
    { dispatch: false }
  );

  async presentAlert(message: string) {
    const alert = await this.alertController.create({
      header: 'Error',
      // subHeader: 'Subtitle',
      mode: 'ios',
      message,
      buttons: ['OK'],
    });

    await alert.present();
  }

  loopBeat$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loopBeatAction),
        tap((action) => {
          this.audioService.loopBeat(action.playerID);
        })
      ),
    { dispatch: false }
  );

  loop$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loopAction),
        tap((action) => {
          this.audioService.setLoop(action.playerID, action.loop, false);
        })
      ),
    { dispatch: false }
  );

  loopIn$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loopInAction),
        tap((action) => {
          this.audioService.setLoopIn(action.playerID);
        })
      ),
    { dispatch: false }
  );

  loopOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loopOutAction),
        tap((action) => {
          this.audioService.setLoopOut(action.playerID);
        })
      ),
    { dispatch: false }
  );

  handleEndOfSongAnimation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setTimeAction),
      concatMap((action) => this.store.select(getRemainingTime(action.playerID)).pipe(withLatestFrom(of(action)), take(1))),
      filter(([remainingTime, action]) => remainingTime < 10000 || remainingTime === 0),
      map(([remainingTime, action]) => {
        if (remainingTime > 0) {
          return startEndOfSongDisplayAnimationAction({ playerID: action.playerID });
        } else {
          return stopEndOfSongDisplayAnimationAction({ playerID: action.playerID });
        }
      })
    )
  );

  toggleActivePlayerBtn$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(activatePlayerAction),
        map((action) => {
          switch (action.playerID) {
            case 1:
              return deactivatePlayerAction({ playerID: 3 });
            case 2:
              return deactivatePlayerAction({ playerID: 4 });
            case 3:
              return deactivatePlayerAction({ playerID: 1 });
            case 4:
              return deactivatePlayerAction({ playerID: 2 });
          }
        })
      ),
    { dispatch: true }
  );

  scratchStart$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(scratchStartAction),
        tap((action) => {
          this.audioService.scratchStart(action.playerID, action.scratchingInformation);
        })
      ),
    { dispatch: false }
  );

  scratchOnWaveform$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(scratchAction),
        tap((action) => {
          this.audioService.scratchOnWaveform(action.playerID, action.scratchingInformation);
        })
      ),
    { dispatch: false }
  );

  scratchEnd$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(scratchEndAction),
        tap((action) => {
          this.audioService.scratchEnd(action.playerID);
        })
      ),
    { dispatch: false }
  );

  reloadWaveform$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setZoomAction),
        tap((action) => {
          this.audioService.setZoom(action.playerID, action.msPxRatio);
        })
      ),
    { dispatch: false }
  );
}
