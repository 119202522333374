import { Injectable, NgZone } from '@angular/core';
import { Store } from '@ngrx/store';
import { saveAs } from 'file-saver';
import { setPitchModeAction, setTimeUM6Action, setTrackTitleUM6Action } from '../core/redux/actions/player.actions';
import { PitchMode } from '../models/pitch-mode';
import { map, Observable, tap } from 'rxjs';
import { UltraMixerLicense } from '../login/interfaces/um-keys-user';
export type Commands = 'SoundPlayer:play';

export class UM6Request {
  request: string;
  onSuccess?: (response: string) => void;
  onFailure?: (error_code: string, error_message: string) => void;
}

@Injectable({
  providedIn: 'root',
})
export class UM6ControlService {
  private query: (request: UM6Request) => void = (window as any).cefQuery;

  constructor(private zone: NgZone, private store: Store) {
    this.registerFunctions();
  }

  play(playerID: number) {
    this.sendCommandToJava({
      request: `SoundPlayer::play::${playerID}`,
      onSuccess: (response) => {
        console.log('>>> response', response);
      },
    });
  }

  cue(playerID: number) {
    this.sendCommandToJava({
      request: `SoundPlayer::cue::${playerID}`,
      onSuccess: (response) => {
        console.log('>>> response', response);
      },
    });
  }

  loopBeat(playerID: number, beat: number) {
    this.sendCommandToJava({
      request: `SoundPlayer::loop::${playerID},${beat}`,
      onSuccess: (response) => {
        console.log('>>> response', response);
      },
    });
  }

  increaseLoopRange(playerID: number) {
    this.sendCommandToJava({
      request: `SoundPlayer::increaseLoopRange::${playerID}`,
      onSuccess: (response) => {
        console.log('>>> response', response);
      },
    });
  }

  decreaseLoopRange(playerID: number) {
    this.sendCommandToJava({
      request: `SoundPlayer::decreaseLoopRange::${playerID}`,
      onSuccess: (response) => {
        console.log('>>> response', response);
      },
    });
  }

  loop(playerID: number, loop: boolean) {
    this.sendCommandToJava({
      request: `SoundPlayer::loop::${playerID},${loop ? 'true' : 'false'}`,
      onSuccess: (response) => {
        console.log('>>> response', response);
      },
    });
  }

  loopIn(playerID: number, time: number) {
    this.sendCommandToJava({
      request: `SoundPlayer::loopIn::${playerID},${time}`,
      onSuccess: (response) => {
        console.log('>>> response', response);
      },
    });
  }

  loopOut(playerID: number, time: number) {
    this.sendCommandToJava({
      request: `SoundPlayer::loopOut::${playerID},${time}`,
      onSuccess: (response) => {
        console.log('>>> response', response);
      },
    });
  }

  setCuePoint(playerID: number, cuePointNumber: number) {
    this.sendCommandToJava({
      request: `SoundPlayer::setCuePoint::${playerID},${cuePointNumber}`,
      onSuccess: (response) => {
        console.log('>>> setCuePoint:response', response);
      },
    });
  }

  loggedIn(message: string) {
    console.log('Logged in, now sending to Java');
    this.sendCommandToJava({
      request: `Login::loginSuccessful::${message}`,
      onSuccess: (response) => {
        console.log(`>>> loginSuccessful; ` + response);
      },
    });
  }

  clickContextMenu(message: string) {
    console.log('Logged in, now sending to Java');
    this.sendCommandToJava({
      request: `Login::clickContextMenu::${message}`,
      onSuccess: (response) => {
        console.log(`>>> clickContextMenu; ` + response);
      },
    });
  }

  sendCommandToJava(request: UM6Request) {
    console.log('>>> sendCommandToJava: this.query', this.query, request);
    if (!this.query) {
      if (request?.onFailure) {
        request?.onFailure('-', 'query is null');
      }
      return;
    }

    if (!request.onFailure) {
      request.onFailure = (error_code, error_message) => {
        //alert('failure: ' + error_code + '-' + error_message);
        console.error(`error while sending commands to java: error-code: ${error_code} - message: ${error_message}`);
      };
    }
    console.log('>>> sendCommandToJava', request);
    this.query(request);
  }

  registerFunctions() {
    (window as any).setTime = (playerID: number, time: number, remainingTime: number) => {
      this.zone.run(() => {
        this.store.dispatch(setTimeUM6Action({ playerID, time, remainingTime }));
      });
    };

    (window as any).setTrackTitle = (
      playerID: number,
      artist: string,
      title: string,
      bpm: number,
      key: string,
      trackNumber: number,
      coverAsBase64: string
    ) => {
      this.zone.run(() => {
        this.store.dispatch(setTrackTitleUM6Action({ playerID, artist, title, bpm, key, trackNumber, coverAsBase64 }));
      });
    };

    (window as any).setPitchMode = (playerID: number, pitchMode: PitchMode) => {
      this.zone.run(() => {
        this.store.dispatch(setPitchModeAction({ playerID, pitchMode }));
      });
    };
  }

  /**
   *
   * @param playerID
   * @param value -1 to 1
   */
  setPitch(playerID: number, value: number): void {
    this.sendCommandToJava({
      request: `SoundPlayer::setPitch::${playerID},${value * 100}`,
      onSuccess: (response) => {
        console.log('>>> SoundPlayer::setPitch-response', response);
      },
    });
  }

  togglePitchMode(playerID: number): void {
    this.sendCommandToJava({
      request: `SoundPlayer::togglePitchMode::${playerID}`,
      onSuccess: (response) => {
        console.log('>>> SoundPlayer::togglePitchMode-response', response);
      },
    });
  }

  toggleActivePlayer(playerID: number): void {
    this.sendCommandToJava({
      request: `SoundPlayer::toggleActivePlayer::${playerID}`,
      onSuccess: (response) => {
        console.log('>>> SoundPlayer::toggleActivePlayer-response', response);
      },
    });
  }

  triggerSoundplayerBPMContextMenu(playerID: number, posX: number, posY: number): void {
    this.sendCommandToJava({
      request: `SoundPlayer::triggerBpmContextMenu::${playerID},${posX},${posY}`,
      onSuccess: (response) => {
        console.log('>>> SoundPlayer::triggerBpmContextMenu-response', response);
      },
    });
  }

  triggerTrackTitleNumberClick(playerID: number): void {
    this.sendCommandToJava({
      request: `SoundPlayer::triggerTrackTitleNumberClick::${playerID}`,
      onSuccess: (response) => {
        console.log('>>> SoundPlayer::triggerTrackTitleNumberClick-response', response);
      },
    });
  }

  triggerTrackTitleClick(playerID: number, posX: number, posY: number): void {
    this.sendCommandToJava({
      request: `SoundPlayer::triggerTrackTitleClick::${playerID},${posX},${posY}`,
      onSuccess: (response) => {
        console.log('>>> SoundPlayer::triggerTrackTitleClick-response', response);
      },
    });
  }

  sendAppReadyEvent(): void {
    this.sendCommandToJava({
      request: `General::applicationIsReady`,
      onSuccess: (response) => {
        console.log('>>> General::application::ready-response', response);
      },
    });
  }

  checkAndUpdateUserLicense$(): Observable<void> {
    return this.getUserLicense$().pipe(
      tap((userLicense: UMUserLicense) => {
        console.log('>>> user license', userLicense);
        alert('License got! ' + userLicense.keyFileName);
      }),
      map(() => void 0)
    );
  }

  /**
   * Gets user license from UltraMixer (Java)
   *  */
  getUserLicense$(): Observable<UMUserLicense> {
    return new Observable((observer) => {
      this.sendCommandToJava({
        request: `General::getUserLicense`,
        onSuccess: (response: string) => {
          const data = JSON.parse(response);
          console.log('>>> General::getUserLicense::getUserLicense', data);

          const result: UMUserLicense = {
            keyFileName: data.keyFileName,
            keyData: atob(data.keyData),
            purchaseID: data.purchaseID,
          };

          observer.next(result);
          observer.complete();

          /*
           const fileType = 'binary/octet-stream';
          var blob = new Blob([result.keyData], { type: fileType });
          console.log('>>> key blob', blob);
          saveAs(blob, fileName);
          */

          // alert(response);
        },
        onFailure: (response: string) => {
          console.log('>>> General::getUserLicense:error', response);
          observer.error(response);
        },
      });
    });
  }

  startUltraMixer$(): Observable<any> {
    return new Observable((observer) => {
      const cmd = `General::startUltraMixer`;

      alert('send command');

      this.sendCommandToJava({
        request: cmd,
        onSuccess: (response) => {
          observer.next(response);
          observer.complete();
        },
        onFailure: (error_code: string, error_message: string) => {
          observer.error({ error_code, error_message });
        },
      });
    });
  }

  restartUltraMixer$(): Observable<any> {
    return new Observable((observer) => {
      const cmd = `General::restartUltraMixer`;

      this.sendCommandToJava({
        request: cmd,
        onSuccess: (response) => {
          observer.next(response);
          observer.complete();
        },
        onFailure: (error_code: string, error_message: string) => {
          observer.error({ error_code, error_message });
        },
      });
    });
  }

  startDemo$(version: 'home' | 'basic' | 'pro'): Observable<any> {
    return new Observable((observer) => {
      const cmd = `Login::startDemo::${version}`;

      this.sendCommandToJava({
        request: cmd,
        onSuccess: (response) => {
          observer.next(response);
          observer.complete();
        },
        onFailure: (error_code: string, error_message: string) => {
          observer.error({ error_code, error_message });
        },
      });
    });
  }

  activateLicense$(license: UltraMixerLicense): Observable<any> {
    return new Observable((observer) => {
      const cmd = `Login::activateLicense::${license.purchaseId}::${license.keyData}`;

      this.sendCommandToJava({
        request: cmd,
        onSuccess: (response) => {
          observer.next(response);
          observer.complete();
        },
        onFailure: (error_code: string, error_message: string) => {
          observer.error({ error_code, error_message });
        },
      });
    });
  }

  getOS$(): Observable<string> {
    return new Observable((observer) => {
      const cmd = `General::getOS`;

      this.sendCommandToJava({
        request: cmd,
        onSuccess: (response) => {
          observer.next(response);
          observer.complete();
        },
        onFailure: (error_code: string, error_message: string) => {
          observer.error({ error_code, error_message });
        },
      });
    });
  }
}
// TODO: move out!
interface UMUserLicense {
  keyFileName: string;
  keyData: string;
  purchaseID: string;
}
