import { random } from 'lodash';
import { Track } from '../music-archive/track';

export const tracksMock: Track[] = [];
for (let i = 1; i <= 20; i++) {
  tracksMock.push({
    _id: `id-${i}`,
    artist: `Artist ${i}`,
    title: `Title ${i}`,
    bpm: random(78, 149),
    length: random(2 * 60, 5 * 60),
    rating: random(1, 5),
    src: './assets/mp3/test.mp3'
  } as Track);
}
