import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MidiInputAction } from '../core/redux/actions/midi.actions';
import { MidiState } from '../core/redux/reducers/midi.reducer';
import { getPlayerState } from '../core/redux/reducers/player.reducer';
import { MidiMessage } from '../models/MidiMessage';
import { MidiSoundControlService } from './midi-sound-control.service';

// TODO: ging ni mehr?
// import MIDIOutput = WebMidi.MIDIOutput;

enum LED {
  PLAY_ON,
  PLAY_OFF,
}

@Injectable({
  providedIn: 'root',
})
export class MidiService {
  private midiAvailable = (navigator as any).requestMIDIAccess !== undefined;

  // input devices
  inputDevices = [];

  // Globals to access them later.
  midiIn = [];
  midiOut = [];

  // static notesOn = new Map();

  private unsubscribe$ = new Subject<void>();

  constructor(private store: Store<MidiState>, private platform: Platform, private msc: MidiSoundControlService) {
    this.midiAvailable = false;

    // it is commented because it was implemented to test
    // if (this.midiAvailable) {
    //   this.connect();

    //   this.store
    //     .select(getPlayerState(1)) // TODO: use playerID!!!
    //     .pipe(takeUntil(this.unsubscribe$))
    //     .subscribe((playerState) => {
    //       this.sendMidiMessage(this.msc.playMessage(playerState.isPlaying), this.midiOut[0]);
    //     });
    // }
  }


  public play(value) {
    console.log('[PLAY]', value);

    // let message;
    // if (value) {
    //     message = this.msc.midiOutMap.get(LED.PLAY_ON);
    // } else {
    //     message = this.msc.midiOutMap.get(LED.PLAY_OFF);
    // }

    // let m = new MidiMessage(message);
    // this.sendMidiMessage(m, this.midiOut[0]);
  }

  public getDevices() {
    return this.midiIn;
  }

  initDevices(midi) {
    // Reset.
    this.midiIn = [];
    this.midiOut = [];

    // MIDI devices that send you data.
    const inputs = midi.inputs.values();
    for (let input = inputs.next(); input && !input.done; input = inputs.next()) {
      this.midiIn.push(input.value);
    }

    // MIDI devices that you send data to.
    const outputs = midi.outputs.values();
    for (let output = outputs.next(); output && !output.done; output = outputs.next()) {
      this.midiOut.push(output.value);
    }

    // this.displayDevices();
    this.startListening();
  }

  midiReady(midi) {
    // Also react to device changes.
    midi.addEventListener('statechange', (event) => {
      this.initDevices(event.target);
    });

    this.initDevices(midi); // see the next section!
  }

  // TODO: : Promise<any>
  public connect() {
    console.log('[midi.service] Connecting Midi...');

    return (navigator as any).requestMIDIAccess({ sysex: true }).then(
      (midi) => this.midiReady(midi),
      (err) => console.error('Failed init. midi', err)
    );
  }

  displayDevices() {
    console.log('[midi.service] Midi Devces: ', this.midiIn.map((device) => `${device.name}`).join(''));
  }

  startListening() {
    this.midiIn.forEach((port) => {
      port.onmidimessage = (msg) => this.dispatchMidi(msg);
    });
  }

  printMidi(msg) {
    const message = new MidiMessage(msg.data[0] & 0x0f, msg.data[0] & 0xf0, msg.data[1], msg.data[2]);
    this.store.dispatch(new MidiInputAction({ midiMessage: message }));
  }

  startListening2() {
    const store = this.store;

    this.midiIn.forEach((port) => {
      port.onmidimessage = (msg) => this.printMidi(msg);
    });
  }

  // TODO: replace any by MIDIOutput
  public sendMidiMessage(mm: MidiMessage, device: any) {
    // --------------------------^------- whitch device to work with?
    // const device = this.midiOut[0];

    const command = mm.command + mm.channel;
    const msgOn = [command, mm.data1, mm.data2];

    if (device) {
      // First send the note on;
      console.log(mm.toString());
      device.send(msgOn);
    }

    // --------------- We are manualy sending note off's -------------------
    // const msgOff = [NOTE_ON, data1, data2];
    //
    // // Then send the note off. You can send this separately if you want
    // // (i.e. when the button is released)
    // device.send(msgOff, Date.now() + duration);
  }

  private dispatchMidi(msg) {
    const midiMessage = new MidiMessage(msg.data[0] & 0x0f, msg.data[0] & 0xf0, msg.data[1], msg.data[2]);
    // this.store.dispatch(new MidiInputAction({midiMessage}));
    // this.msc.handleNewMessage$(midiMessage).subscribe();
    this.msc.handleMessage(midiMessage);
  }
}
