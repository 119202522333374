import { inject, Injectable } from '@angular/core';
import { delay, map, Observable, of, switchMap, throwError } from 'rxjs';
import { UltraMixerLicense, UMKeysFromUser } from '../interfaces/um-keys-user';
import { UM6ControlService } from './../../services/um6-control.service';
import { AuthService } from './auth.service';
import { UMKeyService } from './um-key.service';
import { UmDemoVersion } from '../interfaces/um-demo-version.interface';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private authService = inject(AuthService);
  private umKeyService = inject(UMKeyService);
  private um6ControlService = inject(UM6ControlService);

  constructor() {}

  signIn$(email: string, password: string): Observable<UltraMixerLicense[]> {
    return this.authService.signIn$(email, password).pipe(
      switchMap(() => {
        if (!this.authService.userCredentials.emailVerified) {
          throwError(() => new Error('User is not verified'));
        } else {
          return of(null);
        }
      }),
      switchMap(() => this.umKeyService.fetchKeyDocuments$(email)),
      switchMap((umKeys: UMKeysFromUser) => this.umKeyService.filterKeyDocumemts$(umKeys, '6')),
      switchMap((umKeys: UMKeysFromUser) => this.umKeyService.processKeyDocuments$(umKeys)),
      map(() => this.umKeyService.getUMKeys())
    );
  }

  // TODO: implement!
  uploadUltraMixerLicense$(license: UltraMixerLicense): Observable<UltraMixerLicense[]> {
    return of(null).pipe(
      delay(3000),
      map(() => {
        const licenses = [...this.umKeyService.getUMKeys()];
        licenses.push({
          productName: 'UltraMixer 6 Basic for Windows',
          purchaseId: 234567,
          keyData: null,
          purchaseDate: new Date().toDateString(),
          activated: false,
        });
        return licenses;
      })
    );
  }

  // TODO: implement!
  activateLicense$(license: UltraMixerLicense): Observable<void> {
    return this.um6ControlService.activateLicense$(license).pipe(delay(2000));
  }

  activateDemo$(demoVersion: UmDemoVersion): Observable<any> {
    // TODO: remove delay
    return this.um6ControlService.startDemo$(demoVersion.type).pipe(delay(2000));
  }
}
