import { Component, OnInit } from '@angular/core';
import { EmbeddingModule } from '../embedding.module';
import { WidgetsModule } from "../../main/widgets/widgets.module";

@Component({
  selector: 'audio-sampler-embed',
  templateUrl: './audio-sampler-embed.component.html',
  styleUrls: ['./audio-sampler-embed.component.scss'],
  imports: [EmbeddingModule, WidgetsModule],
  standalone: true,
})
export class AudioSamplerEmbedComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
