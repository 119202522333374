import { CommonModule } from '@angular/common';
import { InjectionToken, NgModule } from '@angular/core';
import { audioContextFactory } from './audio-context-factory';
import { AudioContextProxy } from './audio-context-proxy';
import { isSupportedFactory } from './is-supported-factory';
import { latencyHintToken } from './latency-hint-token';
import * as i0 from "@angular/core";
// eslint-disable-next-line unicorn/prefer-export-from
export { AudioContextProxy as AudioContext };
export const isSupported = new InjectionToken('IS_SUPPORTED_PROMISE');
export class AudioContextModule {
  static forChild() {
    return {
      ngModule: AudioContextModule
    };
  }
  static forRoot(latencyHint) {
    return {
      ngModule: AudioContextModule,
      providers: [{
        deps: [latencyHintToken],
        provide: AudioContextProxy,
        useFactory: audioContextFactory
      }, {
        provide: latencyHintToken,
        useValue: latencyHint
      }]
    };
  }
}
AudioContextModule.ɵfac = function AudioContextModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AudioContextModule)();
};
AudioContextModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: AudioContextModule
});
AudioContextModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [{
    provide: isSupported,
    useFactory: isSupportedFactory
  }],
  imports: [CommonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AudioContextModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      providers: [{
        provide: isSupported,
        useFactory: isSupportedFactory
      }]
    }]
  }], null, null);
})();
