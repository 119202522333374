import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, withHashLocation } from '@angular/router';
import Aura from '@primeng/themes/aura';

import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { provideFirebaseApp } from '@angular/fire/app';
import { browserLocalPersistence, initializeAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideIonicAngular } from '@ionic/angular/standalone';
import { getBrowserLang, provideTransloco } from '@jsverse/transloco';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AudioContextModule } from 'angular-audio-context';
import { StarRatingModule } from 'angular-star-rating';
import { getApp, initializeApp } from 'firebase/app';
import { providePrimeNG } from 'primeng/config';
import { environment } from '../environments/environment';
import { routes } from './app.routes';
import { extModules } from './build-specifics';
import { TranslocoHttpLoader } from './transloco-loader';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      StarRatingModule.forRoot(),
      AudioContextModule.forRoot('balanced'),
      extModules,
      !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 50, connectInZone: true }) : []
    ),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes, withHashLocation()),
    // { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideHttpClient(withFetch()),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimationsAsync(),
    providePrimeNG({
      theme: {
        preset: Aura,
        options: {
          darkModeSelector: '.app-dark',
        },
      },
    }),
    // TODO: use it for deploy:release
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => {
      const auth = initializeAuth(getApp(), {
        persistence: browserLocalPersistence,
        // popupRedirectResolver: browserPopupRedirectResolver,
        popupRedirectResolver: null,
      });
      //connectAuthEmulatorInDevMode(auth);

      return auth;
    }),
    provideFirestore(() => {
      const firestore = getFirestore();
      // connectEmulator(firestore, 'localhost', 8080);
      // enableIndexedDbPersistence(firestore);
      return firestore;
    }),

    // for standalone components
    provideIonicAngular({
      mode: 'ios',
      scrollAssist: false,
      hardwareBackButton: false,
      swipeBackEnabled: false,
    }),
    provideTransloco({
      config: {
        availableLangs: ['de', 'en'],
        fallbackLang: 'en',
        defaultLang: getBrowserLang(),
        reRenderOnLangChange: false,
        prodMode: environment.production,
      },
      loader: TranslocoHttpLoader,
    }),
    // provideClientHydration(),
    /* Pass the BugsnagErrorHandler class along to the providers for your module */
    // { provide: ErrorHandler, useFactory: errorHandlerFactory } // TODO
  ],
};
