import { Component, OnInit } from '@angular/core';
import { Turntable2Component } from '../../main/sound-player/turntable2/turntable2.component';

@Component({
  selector: 'turntable-embed',
  templateUrl: './turntable-embed.component.html',
  styleUrls: ['./turntable-embed.component.scss'],
  imports: [Turntable2Component],
  standalone: true,
})
export class TurntableEmbedComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
