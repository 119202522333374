import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'turntable-web-gl',
  templateUrl: './turntable-web-gl.component.html',
  styleUrls: ['./turntable-web-gl.component.scss'],
})
export class TurntableWebGLComponent implements OnInit {
  @ViewChild('canvas', { static: false }) canvas: ElementRef;

  constructor() {}

  ngOnInit() {
    const canvas: HTMLCanvasElement = this.canvas.nativeElement;
  }
}
