import { inject, Injectable } from '@angular/core';
import { delay, map, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { UltraMixerLicense, UMKeysFromUser } from '../interfaces/um-keys-user';
import { UM6ControlService } from './../../services/um6-control.service';
import { AuthService } from './auth.service';
import { UMKeyService } from './um-key.service';
import { UmDemoVersion } from '../interfaces/um-demo-version.interface';
import { keyDataBase64 } from '../../mocks/mocked-key';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private authService = inject(AuthService);
  private umKeyService = inject(UMKeyService);
  private um6ControlService = inject(UM6ControlService);

  constructor() {}

  signIn$(email: string, password: string): Observable<null> {
    return this.authService.signIn$(email, password).pipe(
      switchMap(() => {
        if (!this.authService.userCredentials.emailVerified) {
          throwError(() => new Error('User is not verified'));
        } else {
          return of(null);
        }
      }),
    );
  }

  signOut$(): Observable<void> {
    return this.authService.signOut$();
  }

  // TODO: implement! remove mock data!
  uploadUltraMixerLicense$(license: UltraMixerLicense): Observable<UMKeysFromUser> {
    console.log('>>> uploading key', license);
    return of(null).pipe(
      delay(3000),
      map(() => {
        const licenses: UMKeysFromUser = this.umKeyService.getUMKeys();
        licenses.umKeys.push({
          productName: 'UltraMixer 6 Pro for Mac',
          purchaseId: 234567,
          keyData: null, // TODO: remove mock key data!
          purchaseDate: new Date().toDateString(),
          activated: false,
        });
        return licenses;
      })
    );
  }

  // TODO: implement!
  activateLicense$(license: UltraMixerLicense): Observable<any> {
    return this.um6ControlService.activateLicense$(license).pipe(
      delay(2000),
      // tap(() => alert('fertsch'))
    );
  }

  activateDemo$(demoVersion: UmDemoVersion): Observable<any> {
    // TODO: remove delay
    return this.um6ControlService.startDemo$(demoVersion.type).pipe(delay(2000));
  }
}
