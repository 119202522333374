import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MixerModule } from '../main/mixer/mixer.module';
import { SoundPlayerModule } from '../main/sound-player/sound-player.module';
import { WidgetsModule } from '../main/widgets/widgets.module';
import { MixerEmbedComponent } from './mixer-embed/mixer-embed.component';

@NgModule({
  declarations: [MixerEmbedComponent],
  imports: [CommonModule, MixerModule, WidgetsModule, SoundPlayerModule],
  exports: [MixerEmbedComponent],
})
export class EmbeddingModule {}
