import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { TestComponentForInputsComponent } from './components/text-component-for-inputs/test-component-for-inputs.component';
import { AudioSamplerEmbedComponent } from './embedding/audio-sampler-embed/audio-sampler-embed.component';
import { MixerEmbedComponent } from './embedding/mixer-embed/mixer-embed.component';
import { SoundPlayerEmbedComponent } from './embedding/sound-player-embed/sound-player-embed.component';
import { TurntableEmbedComponent } from './embedding/turntable-embed/turntable-embed.component';
import { DisplayComponent } from './main/sound-player/display/display.component';
import { PerformanceTestComponent } from './uitests/performance-test/performance-test.component';

const routes: Routes = [
  {
    path: '',
    // redirectTo: 'main/actiontrigger/editor', pathMatch: 'full'
    redirectTo: '/main',
    pathMatch: 'full',
  },

  {
    path: 'main',
    loadChildren: () => import('./main/main.module').then((m) => m.MainPageModule),
  },
  {
    path: 'audiosampler',
    component: AudioSamplerEmbedComponent,
  },

  {
    path: 'player/:playerID',
    component: SoundPlayerEmbedComponent,
  },

  {
    path: 'display/:playerID',
    component: DisplayComponent,
  },
  {
    path: 'turntable',
    component: TurntableEmbedComponent,
  },
  {
    path: 'mixer',
    component: MixerEmbedComponent,
  },
  {
    path: 'performancetest',
    component: PerformanceTestComponent,
  },
  {
    path: 'waveform',
    loadChildren: () => import('./main/waveform/waveform-incl-routes.module').then((m) => m.WaveformInclRoutesModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'preferences/additional',
    loadChildren: () => import('./preferences/additional/additional.module').then((m) => m.AdditionalModule),
  },
  {
    path: 'preferences/midi',
    loadChildren: () => import('./preferences/midi/midi.module').then((m) => m.MidiModule),
  },
  {
    path: 'actiontrigger2',
    loadChildren: () => import('./main/action-trigger/action-trigger.module').then((m) => m.ActionTriggerModule),
  },
  {
    path: 'hid',
    loadChildren: () => import('./hid/hid.module').then((m) => m.HidModule),
  },
  {
    path: 'test-component',
    component: TestComponentForInputsComponent,
    title: 'Komponente testen'
  },

  /*,
  { path: 'groups', loadChildren: './music-archive/groups-browser-mobile/groups-browser-mobile.module#GroupsBrowserMobileModule' }
  */
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // bindToComponentInputs: true,
      useHash: true,
      enableTracing: false,
      bindToComponentInputs: true
    }),

    FormsModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
