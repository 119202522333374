import { Component, inject, OnInit } from '@angular/core';

// import { StatusBar, Style } from '@capacitor/status-bar';
import { Platform } from '@ionic/angular';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';
import { UM6ControlService } from './services/um6-control.service';
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    IonApp,
    IonRouterOutlet,

    /*
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
      mode: 'ios',

      scrollAssist: false,
      hardwareBackButton: false,
      swipeBackEnabled: false,
    }),

    AppRoutingModule,
    // HttpClientModule,
    MainPageModule,
    EmbeddingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSliderModule,
    UitestsModule,
    WaveformModule,



    */
  ],
  templateUrl: './app.component.html',
  // styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  private um6ControlService = inject(UM6ControlService);

  constructor(private platform: Platform) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      //      StatusBar.setStyle({ style: Style.Dark });
      this.um6ControlService.sendAppReadyEvent();
      this.um6ControlService.checkAndUpdateUserLicense$().subscribe(); // TODO: rmeove, only tesgt!
    });
  }

  ngOnInit(): void {}
}
