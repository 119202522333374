import { Injectable, NgZone } from '@angular/core';
import { Store } from '@ngrx/store';
import { setPitchModeAction, setTimeUM6Action, setTrackTitleUM6Action } from '../core/redux/actions/player.actions';
import { PitchMode } from '../models/pitch-mode';

export type Commands = 'SoundPlayer:play';

export class UM6Request {
  request: string;
  onSuccess?: (response: string) => void;
  onFailure?: (error_code: string, error_message: string) => void;
}

@Injectable({
  providedIn: 'root',
})
export class UM6ControlService {
  private query: (request: UM6Request) => void = (window as any).cefQuery;

  constructor(private zone: NgZone, private store: Store) {
    this.registerFunctions();
  }

  play(playerID: number) {
    this.sendCommandToJava({
      request: `SoundPlayer::play::${playerID}`,
      onSuccess: (response) => {
        console.log('>>> response', response);
      },
    });
  }

  cue(playerID: number) {
    this.sendCommandToJava({
      request: `SoundPlayer::cue::${playerID}`,
      onSuccess: (response) => {
        console.log('>>> response', response);
      },
    });
  }

  loopBeat(playerID: number, beat: number) {
    this.sendCommandToJava({
      request: `SoundPlayer::loop::${playerID},${beat}`,
      onSuccess: (response) => {
        console.log('>>> response', response);
      },
    });
  }

  increaseLoopRange(playerID: number) {
    this.sendCommandToJava({
      request: `SoundPlayer::increaseLoopRange::${playerID}`,
      onSuccess: (response) => {
        console.log('>>> response', response);
      },
    });
  }

  decreaseLoopRange(playerID: number) {
    this.sendCommandToJava({
      request: `SoundPlayer::decreaseLoopRange::${playerID}`,
      onSuccess: (response) => {
        console.log('>>> response', response);
      },
    });
  }

  loop(playerID: number, loop: boolean) {
    this.sendCommandToJava({
      request: `SoundPlayer::loop::${playerID},${loop ? 'true' : 'false'}`,
      onSuccess: (response) => {
        console.log('>>> response', response);
      },
    });
  }

  loopIn(playerID: number, time: number) {
    this.sendCommandToJava({
      request: `SoundPlayer::loopIn::${playerID},${time}`,
      onSuccess: (response) => {
        console.log('>>> response', response);
      },
    });
  }

  loopOut(playerID: number, time: number) {
    this.sendCommandToJava({
      request: `SoundPlayer::loopOut::${playerID},${time}`,
      onSuccess: (response) => {
        console.log('>>> response', response);
      },
    });
  }

  setCuePoint(playerID: number, cuePointNumber: number) {
    this.sendCommandToJava({
      request: `SoundPlayer::setCuePoint::${playerID},${cuePointNumber}`,
      onSuccess: (response) => {
        console.log('>>> setCuePoint:response', response);
      },
    });
  }

  sendCommandToJava(request: UM6Request) {
    if (!this.query) return;

    if (!request.onFailure) {
      request.onFailure = (error_code, error_message) => {
        //alert('failure: ' + error_code + '-' + error_message);
        console.error(`error while sending commands to java: error-code: ${error_code} - message: ${error_message}`);
      };
    }
    console.log('>>> sendCommandToJava', request.request);
    this.query(request);
  }

  registerFunctions() {
    (window as any).setTime = (playerID: number, time: number, remainingTime: number) => {
      this.zone.run(() => {
        this.store.dispatch(setTimeUM6Action({ playerID, time, remainingTime }));
      });
    };

    (window as any).setTrackTitle = (playerID: number, artist: string, title: string, bpm: number, key: string, trackNumber: number, coverAsBase64: string) => {
      this.zone.run(() => {
        this.store.dispatch(setTrackTitleUM6Action({ playerID, artist, title, bpm, key, trackNumber, coverAsBase64 }));
      });
    };

    (window as any).setPitchMode = (playerID: number, pitchMode: PitchMode) => {
      this.zone.run(() => {
        this.store.dispatch(setPitchModeAction({ playerID, pitchMode }));
      });
    };
  }

  /**
   *
   * @param playerID
   * @param value -1 to 1
   */
  setPitch(playerID: number, value: number): void {
    this.sendCommandToJava({
      request: `SoundPlayer::setPitch::${playerID},${value * 100}`,
      onSuccess: (response) => {
        console.log('>>> SoundPlayer::setPitch-response', response);
      },
    });
  }

  togglePitchMode(playerID: number): void {
    this.sendCommandToJava({
      request: `SoundPlayer::togglePitchMode::${playerID}`,
      onSuccess: (response) => {
        console.log('>>> SoundPlayer::togglePitchMode-response', response);
      },
    });
  }

  toggleActivePlayer(playerID: number): void {
    this.sendCommandToJava({
      request: `SoundPlayer::toggleActivePlayer::${playerID}`,
      onSuccess: (response) => {
        console.log('>>> SoundPlayer::toggleActivePlayer-response', response);

      },
    });
  }

  triggerSoundplayerBPMContextMenu(playerID: number, posX: number, posY: number): void {
    this.sendCommandToJava({
      request: `SoundPlayer::triggerBpmContextMenu::${playerID},${posX},${posY}`,
      onSuccess: (response) => {
        console.log('>>> SoundPlayer::triggerBpmContextMenu-response', response);
      }
    });
  }

  triggerTrackTitleNumberClick(playerID: number): void {
    this.sendCommandToJava({
      request: `SoundPlayer::triggerTrackTitleNumberClick::${playerID}`,
      onSuccess: (response) => {
        console.log('>>> SoundPlayer::triggerTrackTitleNumberClick-response', response);
      }
    });
  }

  triggerTrackTitleClick(playerID: number, posX: number, posY: number): void {
    this.sendCommandToJava({
      request: `SoundPlayer::triggerTrackTitleClick::${playerID},${posX},${posY}`,
      onSuccess: (response) => {
        console.log('>>> SoundPlayer::triggerTrackTitleClick-response', response);
      }
    });
  }

  sendAppReadyEvent(): void {
    this.sendCommandToJava({
      request: `General::applicationIsReady`,
      onSuccess: (response) => {
        console.log('>>> General::application::ready-response', response);
      }
    });
  }
}
