import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NotePadComponent } from '../../main/widgets/note-pad/note-pad.component';

@Component({
  selector: 'note-pad-embed',
  templateUrl: './note-pad-embed.component.html',
  styleUrls: ['./note-pad-embed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NotePadComponent],
})
export class NotePadEmbedComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
