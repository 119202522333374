import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { UM6ControlService } from 'src/app/services/um6-control.service';
import { keyDataBase64 } from '../../mocks/mocked-key';
import { UMKeysFromUser, UltraMixerLicense } from '../interfaces/um-keys-user';

@Injectable({
  providedIn: 'root',
})
export class UMKeyService {
  private http = inject(HttpClient);
  private um6ControlService = inject(UM6ControlService);
  private router = inject(Router);

  // remove!
  private umKeys: UltraMixerLicense[] = [
    /*
      {
        productName: 'UltraMixer 6 Home for Mac',
        purchaseId: 234567,
        keyData: null,
        purchaseDate: new Date().toDateString(),
      },
      {
        productName: 'UltraMixer 6 Basic for Windows',
        purchaseId: 234567,
        keyData: null,
        purchaseDate: new Date().toDateString(),
      },*/

    {
      productName: 'UltraMixer 6 Pro Entertain for Windows',
      purchaseId: 876543,
      keyData: {data: [], type: 'buffer'},
      purchaseDate: new Date().toDateString(),
    },
  ];

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  //TODO change names and refactor
  public getUMKeys(): UMKeysFromUser {
    return { mail: 'unknown', umKeys: this.umKeys };
  }

  public fetchUMKeys$(email: string, os: string): Observable<UMKeysFromUser> {
    return this.fetchKeyDocuments$(email).pipe(
      switchMap((umKeys: UMKeysFromUser) => this.filterKeyDocumemts$(umKeys, '6', os))
    );
  }

  private fetchKeyDocuments$(email: string): Observable<Object> {
    return this.http.post('https://europe-west1-seriennummeraktivierung-beb6e.cloudfunctions.net/getKeyDocumentsFromFirestore', email);
  }

  //decide what to do with the input based on the number of keys
  public processKeyDocuments$(input: UMKeysFromUser): Observable<null> {
    const umKeys = input.umKeys;
    if (umKeys.length == 0) {
      //start with demo key
      this.um6ControlService.startDemo$('pro');
    } else if (umKeys.length == 1) {
      const currentKey = umKeys[0];
      this.loggedIn(currentKey);
    } else {
      //user needs to select the key he wants to use because he has more then one
      this.selectUltramixerKey(umKeys);
    }
    return of(null);
  }

  // //filter the UM keys for the version you are using
  public filterKeyDocumemts$(userData: UMKeysFromUser, umVersion: string, os: string): Observable<UMKeysFromUser> {
    return of(null).pipe(
      switchMap(() => {
        let newData: UMKeysFromUser = { mail: '', umKeys: [] };
        newData.mail = userData.mail;
        userData.umKeys.forEach((key) => {
          if (key.productName.includes(umVersion) && key.productName.toLowerCase().includes('ultramixer') &&
            (key.productName.toLowerCase().includes(os) || key.productName.toLowerCase().includes('cross'))) {
            newData.umKeys.push(key);
          }
        });
        return of(newData);
      })
    );
  }

  public loggedIn(currentKey: UltraMixerLicense) {
    const parameters: string = this.buildParameters(currentKey);
    this.um6ControlService.loggedIn(parameters);
  }

  public clickContextMenu(menuAction: string) {
    this.um6ControlService.clickContextMenu(menuAction);
  }

  //build the parameters for the java request in the form (keydata, purchaseId)
  private buildParameters(currentKey: UltraMixerLicense): string {
    let parameters: string = '';
    let data: string = currentKey.keyData.data.toString();
    data = data.split(',').join(';');
    parameters += data + ',';
    parameters += currentKey.purchaseId;
    return parameters;
  }

  //route to the um selection site
  private selectUltramixerKey(umKeys: UltraMixerLicense[]) {
    // this.umKeys = umKeys; // TODO: wieder rein!
    this.router.navigate(['/login/licenses']);
  }
}
