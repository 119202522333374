import { Injectable } from '@angular/core';
import { UltraMixerLicense, UMKeysFromUser } from '../interfaces/um-keys-user';
import { Observable, map, of, switchMap, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UM6ControlService } from 'src/app/services/um6-control.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UMKeyService {
  // remove!
  private umKeys: UltraMixerLicense[] = [
    /*
      {
        productName: 'UltraMixer 6 Home for Mac',
        purchaseId: 234567,
        keyData: null,
        purchaseDate: new Date().toDateString(),
      },
      {
        productName: 'UltraMixer 6 Basic for Windows',
        purchaseId: 234567,
        keyData: null,
        purchaseDate: new Date().toDateString(),
      },*/
    {
      productName: 'UltraMixer 6 Pro Entertain for Windows',
      purchaseId: 876543,
      keyData: null,
      purchaseDate: new Date().toDateString(),
    },
  ];

  constructor(private http: HttpClient, private um6ControlService: UM6ControlService, private router: Router) {}

  public getUMKeys(): UltraMixerLicense[] {
    return this.umKeys;
  }

  public fetchKeyDocuments$(email: string): Observable<Object> {
    return this.http.post('https://europe-west1-seriennummeraktivierung-beb6e.cloudfunctions.net/getKeyDocumentsFromFirestore', email);
  }

  //decide what to do with the input based on the number of keys
  public processKeyDocuments$(input: UMKeysFromUser): Observable<null> {
    const umKeys = input.umKeys;
    if (umKeys.length == 0) {
      //start with demo key
      this.um6ControlService.startDemo$('pro');
    } else if (umKeys.length == 1) {
      const currentKey = umKeys[0];
      this.loggedIn(currentKey);
    } else {
      //user needs to select the key he wants to use because he has more then one
      this.selectUltramixerKey(umKeys);
    }
    return of(null);
  }

  // //filter the UM keys for the version you are using
  public filterKeyDocumemts$(userData: UMKeysFromUser, umVersion: string) {
    return of(null).pipe(
      switchMap(() => {
        let newData: UMKeysFromUser = { mail: '', umKeys: [] };
        newData.mail = userData.mail;
        userData.umKeys.forEach((key) => {
          if (key.productName.includes(umVersion) && key.productName.toLowerCase().includes('ultramixer')) {
            newData.umKeys.push(key);
          }
        });
        return of(newData);
      })
    );
  }

  public loggedIn(currentKey: UltraMixerLicense) {
    const parameters: string = this.buildParameters(currentKey);
    this.um6ControlService.loggedIn(parameters);
  }

  public clickContextMenu(menuAction: string) {
    this.um6ControlService.clickContextMenu(menuAction);
  }

  //build the parameters for the java request in the form (keydata, purchaseId)
  private buildParameters(currentKey: UltraMixerLicense): string {
    let parameters: string = '';
    let data: string = currentKey.keyData.data.toString();
    data = data.split(',').join(';');
    parameters += data + ',';
    parameters += currentKey.purchaseId;
    return parameters;
  }

  //route to the um selection site
  private selectUltramixerKey(umKeys: UltraMixerLicense[]) {
    // this.umKeys = umKeys; // TODO: wieder rein!
    this.router.navigate(['/login/licenses']);
  }
}
